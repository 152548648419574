import { Link } from "react-router-dom";

import { ReactComponent as IconPlus } from "../../images/icon-plus.svg";
import LedgerCard from "@components/ledger/LedgerCard";

type DashboardLedgersProps = {
  ledgers: any[];
};

const DashboardLedgers: React.FunctionComponent<DashboardLedgersProps> = ({
  ledgers,
}: DashboardLedgersProps) => {
  const ledgerSlice = ledgers.slice(0, 5);

  return (
    <>
      <div className="w-full">
        {ledgers.length > 0 && (
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl md:text-2xl font-semibold">Your Ledgers</h3>
            <div className="text-right lg:col-span-2">
              <Link
                to="/ledgers/new"
                className="inline-flex items-center py-2 px-3 border border-transparent text-sm font-semibold rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <IconPlus className="w-4 mr-1" />
                Create Ledger
              </Link>
            </div>
          </div>
        )}
        {!ledgers.length && (
          <div className="items-center mb-6">
            <div className="text-center p-5 lg:col-span-2">
              <p className="text-lg block mb-6 text-gray-700">
                You have not created any ledgers yet. This is the first step to
                storing and securing your data.
              </p>
              <Link
                to="/ledgers/new"
                className="inline-flex items-center py-2 px-3 border border-transparent text-sm font-semibold rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500-lg text-lg"
              >
                <IconPlus className="w-4 mr-1" />
                Create Your First Ledger
              </Link>
            </div>
          </div>
        )}
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
          {ledgerSlice.map((ledger) => (
            <LedgerCard ledger={ledger} key={ledger.id} />
          ))}
          {ledgers.length > 5 && (
            <div className="p-3 flex flex-col justify-between">
              <p>
                You have more ledgers, we are just showing the first few here.
              </p>
              <div className="text-right">
                <Link to="/ledgers" className="inline-block textlink p-2">
                  View all{" "}
                  <i className="fal fa-chevron-double-right fa-sm ml-1"></i>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardLedgers;

import LedgerService from "../services/LedgerService";
import EntryService from "../services/EntryService";
import RuleService from "../services/RuleService";
import NotificationService from "@services/NotificationService";

const ledgerService = new LedgerService();
const entryService = new EntryService();
const ruleService = new RuleService();
const notificationService = new NotificationService();

export const ledgersQuery = async () => {
  const { data } = await ledgerService.getAll();
  return data;
};

export const ledgerQuery = async (id: string) => {
  const { data } = await ledgerService.getOne(id);
  return data;
};

export const entryQuery = async (id: string) => {
  const { data } = await entryService.getOne(id);
  return data;
};

export const entriesQuery = async () => {
  const { data } = await entryService.getAll();
  return data;
};

export const rulesQuery = async (definitionId: string) => {
  if (!definitionId) return [];
  const { data } = await ruleService.getAll({ definitionId });
  return data;
};

export const notificationsQuery = async () => {
  const { data } = await notificationService.getAll();
  return data;
};

import { motion } from "framer-motion";
import PromoContent from "./promo/PromoContent";
import Hero from "./promo/Hero";
import Alternating from "./promo/Alternating";
import GradientFeature from "./promo/GradientFeature";
import LogoCloud from "./promo/LogoCloud";
import Stats from "./promo/Stats";
import CallToAction from "./promo/CallToAction";

const Promo: React.FunctionComponent = () => {
  return (
    <motion.div
      className="flex-grow"
      key="form"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <PromoContent>
        <Hero />
        {false && <LogoCloud />}
        <Alternating />
        <GradientFeature />
        <CallToAction />
        <Stats />
      </PromoContent>
    </motion.div>
  );
};

export default Promo;

import { formatPercentage, shortDate, shortTime } from "@utils/format";
import { useState } from "react";
import EntryVCModal from "@components/layout/EntryVCModal";
import Modal from "@components/layout/Modal";

type EntryEventsProps = {
  entry: any;
  definition: any;
};

const EntryEvents: React.FunctionComponent<EntryEventsProps> = ({
  entry,
  definition,
}: EntryEventsProps) => {
  const eventEntries = Object.entries(entry.event);
  const [visibleItems, setVisibleItems] = useState(eventEntries.slice(0, 5));
  const [displayShowAllButton, setDisplayShowAllButton] = useState(() => {
    return eventEntries.length > 5 ? true : false;
  });
  const [isVCModalOpen, setIsVCModalOpen] = useState(false);
  const [VCData, setVCData] = useState("");

  const handleOpenVCModal = (data: any) => {
    setVCData(data);
    setIsVCModalOpen(true);
  };

  const handleCloseVCModal = () => {
    setIsVCModalOpen(false);
  };

  if (!entry.event) return null;

  const fieldForKey = (key: any) => {
    if (!definition) return 1;
    return definition.fields.find((field: any) => field.name === key) ?? 1;
  };

  const renderValue = (key: any, value: any) => {
    let returnValue = <span className="text-gray-400">(No data)</span>;
    const { isMultiple } = fieldForKey(key);

    if (isMultiple) {
      if (value.length > 0) {
        returnValue = (
          <ul className="flex flex-col">
            {value.map((singleValue: any, i: any) => (
              <li
                key={i}
                className="inline-block border-dashed border-b border-gray-200"
              >
                {typedValue(key, singleValue)}
              </li>
            ))}
          </ul>
        );
      }
    } else {
      if (typedValue(key, value)) {
        returnValue = typedValue(key, value);
      }
    }

    return returnValue;
  };

  const typedValue = (key: any, value: any) => {
    let returnValue;
    const { type } = fieldForKey(key);

    switch (type) {
      case 3:
        returnValue = (
          <>
            {shortDate(new Date(value))}
            <span className="ml-2 text-gray-500">
              {shortTime(new Date(value))}
            </span>
          </>
        );
        break;
      case 4:
        returnValue = value ? "true" : "false";
        break;
      case 5:
        returnValue = shortDate(new Date(value));
        break;
      case 9:
        returnValue = formatPercentage(value);
        break;
      case 11:
        if (value)
          returnValue = (
            <dl className="grid grid-cols-1">
              {value?.entry?.event ? (
                Object.keys(value.entry.event)
                  .filter((key) =>
                    ["string", "number", "object"].includes(
                      typeof value.entry.event[key]
                    )
                  )
                  .map((key) => {
                    const keyString = `${key}-${value.entry.id}`;
                    const eventValue = value.entry.event[key];
                    const subEventObject =
                      typeof eventValue?.entry?.event === "object"
                        ? eventValue?.entry?.event
                        : undefined;
                    let subEventObjectKey: any = undefined;
                    if (subEventObject) {
                      subEventObjectKey = Object.keys(subEventObject)[0];
                    }

                    return (
                      <div key={keyString} className="flex sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          {key}:
                        </dt>
                        <dd className="pl-2 text-sm text-gray-700">
                          {typeof eventValue !== "object"
                            ? String(eventValue)
                            : subEventObject
                            ? subEventObject[subEventObjectKey]
                            : "Invalid Data"}
                        </dd>
                      </div>
                    );
                  })
              ) : (
                <>Invalid Data</>
              )}
            </dl>
          );
        break;
      default:
        returnValue = value;
    }

    return returnValue;
  };

  return (
    <div className="overflow-hidden">
      <dl>
        <div className="divide-y divide-gray-100">
          {visibleItems.map(([key, value]) => (
            <div
              key={key}
              className="py-2 lg:grid lg:grid-cols-entry-list-item lg:gap-2"
            >
              <dt className="text-sm font-medium text-gray-800 whitespace-normal break-words">
                {key}
              </dt>
              <dd className="text-sm text-gray-800 lg:mt-0 whitespace-normal break-words">
                {renderValue(key, value)}
              </dd>
            </div>
          ))}
          {!displayShowAllButton && entry.vc && (
            <dd className="text-sm text-gray-800 lg:mt-0 whitespace-normal break-words ">
              <Modal open={isVCModalOpen}>
                <div className="flex flex-col ">
                  <div className="p-4 space-y-5 ">
                    <EntryVCModal data={VCData} onClose={handleCloseVCModal} />
                  </div>
                </div>
              </Modal>
              <button
                className="font-bold text-blue-500 text-sm mt-2"
                onClick={() => handleOpenVCModal(entry.vc)}
              >
                Show VC details
              </button>
            </dd>
          )}
        </div>
      </dl>
      {displayShowAllButton && (
        <button
          onClick={(e) => {
            e.preventDefault();
            setVisibleItems(eventEntries);
            setDisplayShowAllButton(false);
          }}
          className="block mx-auto my-2 px-3 py-1 text-sm text-gray-700 font-medium rounded-md border hover:bg-gray-50"
        >
          Show all
        </button>
      )}
    </div>
  );
};

export default EntryEvents;

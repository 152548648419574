import { Link } from "react-router-dom";

import BearImage from "../../images/polarbear-white.jpg";
import screenshot from "../../images/screenshot.png";

const Alternating: React.FunctionComponent = () => {
  return (
    <div className="relative pt-16 pb-32 overflow-hidden">
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
      ></div>
      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <h2 className="text-sm font-semibold tracking-wide uppercase">
                <span className="bg-gradient-to-r from-purple-600 to-indigo-300 bg-clip-text text-transparent">
                  Our Product
                </span>
              </h2>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Trustworthy ESG-Related Disclosure
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Get the benefits of blockchain without the cost of hiring
                  resources to build your own solution. Prove transactions,
                  provide audit trails, and comply to ESG-related disclosure
                  reporting requirements.
                </p>
              </div>
            </div>
            <div className="mt-8 pt-6">
              <div className="grid grid-cols-3 gap-5">
                <div className="border-t-2 border-purple-400">
                  <h2 className="mt-5 text-xl font-bold tracking-tight text-gray-900 uppercase">
                    Tamper-evident
                  </h2>
                </div>

                <div className="border-t-2 border-purple-400">
                  <h2 className="mt-5 text-xl font-bold tracking-tight text-gray-900 uppercase">
                    Immutable audit-log
                  </h2>
                </div>

                <div className="border-t-2 border-purple-400">
                  <h2 className="mt-5 text-xl font-bold tracking-tight text-gray-900 uppercase">
                    Integrity
                  </h2>
                </div>

                <div className="border-b-2 border-purple-400">
                  <p>
                    <span className="mt-2 mb-4 block text-base text-gray-500">
                      It
                      <span className="font-medium text-gray-900">
                        {" "}
                        verifies{" "}
                      </span>
                      ESG-related Events added to the Ledger have not been
                      tampered with or falsified.
                    </span>
                  </p>
                </div>

                <div className="border-b-2 border-purple-400">
                  <p>
                    <span className="mt-2 mb-4 block text-base text-gray-500">
                      It
                      <span className="font-medium text-gray-900">
                        {" "}
                        creates{" "}
                      </span>
                      an audit log of all verified ESG-related historical Events
                      added to the Ledger that cannot be changed or deleted.
                    </span>
                  </p>
                </div>

                <div className="border-b-2 border-purple-400">
                  <p>
                    <span className="mt-2 mb-4 block text-base text-gray-500">
                      It
                      <span className="font-medium text-gray-900">
                        {" "}
                        guarantees{" "}
                      </span>
                      the integrity of the ESG-related Events added to the
                      Ledger for confident disclosure of assertions in real
                      time.
                    </span>
                  </p>
                </div>
              </div>

              <div className="mt-6 flex justify-center">
                <Link
                  to="/signup"
                  className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700"
                >
                  Get started
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src={screenshot}
                alt="Inbox user interface"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600">
                  <svg
                    className="h-6 w-6 text-white"
                    x-description="Heroicon name: outline/sparkles"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                    ></path>
                  </svg>
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Building public confidence and trust
                </h2>

                <p>
                  <span className="mt-4 block text-lg text-gray-500">
                    We believe that trusted and independent ESG-related
                    disclosure for all to access is not just a good thing to do;
                    it is the
                    <span className="font-medium text-purple-900 italic">
                      {" "}
                      right{" "}
                    </span>
                    thing to do.
                  </span>
                </p>

                <div className="mt-6">
                  <a
                    href="/signup"
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:relative lg:h-full">
              <img
                className="w-full lg:absolute lg:h-full lg:w-auto lg:max-w-none"
                src={BearImage}
                alt="Polar Bear"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alternating;

import { useContext, useState } from "react";
import { Formik, ErrorMessage, Form } from "formik";
import FormSuccess from "./layout/FormSuccess";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import dataskerLogo from "../images/logo512.png";
import RouteTransition from "./layout/RouteTransition";
import UserService from "../services/UserService";
import { ACTIONS, LoginContext } from "@utils/reducers";
import OtpInput from "react18-input-otp";
import SignInFooter from "./layout/SignInFooter";

const userService = new UserService();

const Login: React.FunctionComponent = () => {
  const { dispatch } = useContext(LoginContext);
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [step, setStep] = useState(1);
  const [tel, setTel] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resendSuccess, setResendSuccess] = useState(false);

  const validate = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const maskPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber.length === 0) {
      return phoneNumber;
    }
    const maskedPhoneNumber =
      phoneNumber.substring(0, 5) +
      "*".repeat(phoneNumber.length - 7) +
      phoneNumber.substring(phoneNumber.length - 2, phoneNumber.length);
    return maskedPhoneNumber;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmission = async (
    { email, password, code, mfaMethod }: any,
    actions: any
  ) => {
    actions.setSubmitting(true);
    setIsLoading(true);
    setFormErrors([]);
    setResendSuccess(false);
    try {
      if (step === 1) {
        const response = await userService.logIn(email, password);

        if (response?.status === 200) {
          setTel(response.data.user.tel);
          setStep(2);
        } else if (response?.status === 401) {
          setFormErrors(["Password is incorrect, please try again!"]);
        } else {
          setFormErrors(["Unable to log in, please try again!."]);
        }
      } else if (step === 2) {
        const response = await userService.handleLoginVerification(
          email,
          password,
          mfaMethod
        );
        if (response?.status === 200) {
          setStep(3);
        } else {
          setFormErrors(["Unable to send code, please try again!"]);
        }
      } else if (step === 3) {
        const verificationResponse =
          await userService.handleLoginVerificationCode(
            email,
            password,
            code,
            mfaMethod
          );
        if (verificationResponse?.status === 200) {
          const response = await userService.logIn(email, password);
          dispatch({
            type: ACTIONS.SET_USER,
            payload: { ...response.data, loggedIn: true },
          });
          userService.saveUser(response.data);
          navigate("/");
        } else {
          setFormErrors(["Verification code is incorrect, please try again!"]);
        }
      }
    } catch (error) {
      const err = error as any;
      if (err.response.status === 400) {
        setFormErrors([err.response.data.errors[0].message.split(":")[1]]);
      } else {
        setFormErrors(["An unexpected error occurred, please try again!"]);
      }
    } finally {
      actions.setSubmitting(false);
      setIsLoading(false);
    }
  };

  const resendCode = async (
    email: string,
    password: string,
    mfaMethod: string
  ) => {
    setIsLoading(true);
    setFormErrors([]);
    try {
      const response = await userService.handleLoginVerification(
        email,
        password,
        mfaMethod
      );
      if (response?.status === 200) {
        setResendSuccess(true);
      } else {
        setFormErrors([
          "Unable to resend verification code, please try again!",
        ]);
      }
    } catch (error) {
      setFormErrors(["An unexpected error occurred while resending the code."]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RouteTransition>
      <Formik
        initialValues={{
          email: "",
          password: "",
          tel: tel,
          code: "",
          mfaMethod: "email",
        }}
        validationSchema={validate}
        onSubmit={handleSubmission}
      >
        {(props) => (
          <Form>
            <div className="mt-20 mb-20 bg-zinc-700">
              <div className=" mx-auto my-6">
                <img
                  src={dataskerLogo}
                  alt="Datasker Logo"
                  className="sm:h-32 h-20 m-auto"
                />
                <div className="m-auto flex my-4 justify-center sm:text-3xl md:text-5xl text-lg text-cyan-700 font-semibold">
                  Sign In to your Datasker Account
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2 max-w-2xl m-auto">
                  {formErrors.length > 0 && (
                    <div className="mb-5 p-5 text-sm bg-red-200 rounded-md border-red-400">
                      <h4 className="mb-2 text-md font-semibold">
                        Unable to Log In
                      </h4>
                      <ul>
                        {formErrors.map((error, index) => (
                          <li key={index}>- {error}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <div className=" sm:overflow-hidden bg-zinc-700">
                    <div className="px-4 py-5 space-y-6 sm:p-6 text-align ml-10 mr-10">
                      {step === 1 && (
                        <div>
                          <div className="grid grid-cols-1 gap-3">
                            <div className="col-span-1 sm:col-span-1">
                              <label
                                htmlFor="email"
                                className="text-lg font-medium text-white flex items-end"
                              >
                                Email
                                <p className="font-extralight text-base inline ml-2 opacity-70">
                                  (required)
                                </p>
                              </label>
                              <div className="mt-1 flex">
                                <input
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.email}
                                  type="text"
                                  name="email"
                                  id="email"
                                  className="p-2 focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-md sm:text-sm border border-gray-300"
                                  placeholder=""
                                />
                              </div>
                              <ErrorMessage
                                component="p"
                                className="sm:text-xs text-red-600 pt-2"
                                name="email"
                              />
                            </div>
                          </div>
                          <div className="grid grid-cols-1 gap-3 mt-5 mb-2">
                            <div className="col-span-1 sm:col-span-1">
                              <label
                                htmlFor="password"
                                className=" text-lg font-medium text-white flex items-end"
                              >
                                Password
                                <p className="font-extralight text-base inline ml-2 opacity-70">
                                  (required)
                                </p>
                              </label>
                              <div className="mt-1 flex rounded-md shadow-sm relative">
                                <input
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.password}
                                  type={showPassword ? "text" : "password"}
                                  name="password"
                                  id="password"
                                  className="p-2 focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-md sm:text-sm border border-gray-300"
                                  placeholder=""
                                />
                                <span
                                  onClick={togglePasswordVisibility}
                                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                >
                                  <i
                                    className={
                                      showPassword
                                        ? "fa fa-eye-slash"
                                        : "fa fa-eye"
                                    }
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </div>
                              <ErrorMessage
                                component="p"
                                className="sm:text-xs text-red-600 pt-2"
                                name="password"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {step === 2 && (
                        <div className="grid grid-cols-1 gap-3">
                          <div className="col-span-1 sm:col-span-1">
                            <label
                              htmlFor="mfaMethod"
                              className=" text-lg font-medium text-white flex items-end"
                            >
                              Please select your verification method
                            </label>
                            <div className="mt-1 flex flex-col rounded-md shadow-sm text-lg font-light text-white mb-3">
                              <label>
                                <input
                                  type="radio"
                                  name="mfaMethod"
                                  value="email"
                                  className="mb-5 mt-5"
                                  checked={props.values.mfaMethod === "email"}
                                  onChange={props.handleChange}
                                />
                                <p className="ml-2 inline">
                                  Email: {props.values.email}
                                </p>
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  name="mfaMethod"
                                  value="phone_number"
                                  checked={
                                    props.values.mfaMethod === "phone_number"
                                  }
                                  onChange={props.handleChange}
                                />
                                <p className="ml-2 inline">
                                  Phone Number: {maskPhoneNumber(tel)}
                                </p>
                              </label>
                            </div>
                            <ErrorMessage
                              component="p"
                              className="sm:text-xs text-red-600 pt-2"
                              name="mfaMethod"
                            />
                          </div>
                        </div>
                      )}
                      {step === 3 && (
                        <div className="grid grid-cols-1 gap-3">
                          <FormSuccess show={resendSuccess}>
                            Your code has been resent.
                          </FormSuccess>
                          <div className="col-span-1 sm:col-span-1">
                            {props.values.mfaMethod === "email" && (
                              <h3 className=" text-base font-light text-white flex items-end mb-3">
                                Verification code has been sent to you email:{" "}
                                {props.values.email}
                              </h3>
                            )}
                            {props.values.mfaMethod === "phone_number" && (
                              <h3 className=" text-base font-light text-white flex items-end mb-3">
                                Verification code has been sent to you mobile:{" "}
                                {maskPhoneNumber(props.values.tel)}
                              </h3>
                            )}
                            <label
                              htmlFor="code"
                              className=" text-lg font-medium text-white flex items-end mb-5"
                            >
                              Verification Code
                              <p className="font-extralight text-base inline ml-2 opacity-70">
                                (required)
                              </p>
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <OtpInput
                                value={props.values.code}
                                onChange={(value: any) =>
                                  props.setFieldValue("code", value)
                                }
                                numInputs={6}
                                inputStyle="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none text-5xl 2xl:text-6xl text-center"
                                containerStyle="w-full flex gap-4 mt-3 justify-center"
                                errorStyle="border-red-300"
                                isInputNum={true}
                                isDisabled={isLoading}
                              />
                            </div>
                            <ErrorMessage
                              component="p"
                              className="sm:text-xs text-red-600 pt-2"
                              name="code"
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="flex m-auto items-center">
                      {step === 1 && (
                        <div className="flex m-auto items-center">
                          {isLoading && (
                            <button
                              disabled={true}
                              className="cursor-not-allowed h-12 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded text-base bg-cyan-600 opacity-30 hover:bg-cyan-500 text-white border-x-8 m-auto"
                            >
                              Sign In
                            </button>
                          )}
                          {!isLoading && (
                            <button
                              type="submit"
                              className="h-12 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded text-base bg-cyan-600 opacity-90 hover:bg-cyan-500 text-white border-x-8 m-auto"
                            >
                              Sign In
                            </button>
                          )}
                        </div>
                      )}
                      {step === 2 && (
                        <div className="flex m-auto items-center">
                          {isLoading && (
                            <button
                              disabled={true}
                              className="cursor-not-allowed h-12 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded text-base bg-cyan-600 opacity-30 hover:bg-cyan-500 text-white border-x-8 m-auto"
                            >
                              Send Code
                            </button>
                          )}
                          {!isLoading && (
                            <button
                              type="submit"
                              className="h-12 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded text-base bg-cyan-600 opacity-90 hover:bg-cyan-500 text-white border-x-8 m-auto"
                            >
                              Send Code
                            </button>
                          )}
                        </div>
                      )}
                      {step === 3 && (
                        <div className="flex m-auto items-center">
                          {isLoading && (
                            <div>
                              <div className="flex m-auto items-center">
                                <button
                                  disabled={true}
                                  className="cursor-not-allowed h-12 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded text-base bg-cyan-600 opacity-30 hover:bg-cyan-500 text-white border-x-8 m-auto "
                                >
                                  Verify
                                </button>
                              </div>
                              <div className="flex m-auto items-center mt-8 text-white">
                                Didn't receive a code?{" "}
                                <button
                                  disabled={true}
                                  className="cursor-not-allowed opacity-30 text-cyan-300 hover:underline hover:text-cyan-800 ml-2"
                                >
                                  Resend Code
                                </button>
                              </div>
                            </div>
                          )}
                          {!isLoading && (
                            <div>
                              <div className="flex m-auto items-center">
                                <button
                                  type="submit"
                                  className="h-12 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded text-base bg-cyan-600 opacity-90 hover:bg-cyan-500 text-white border-x-8 m-auto"
                                >
                                  Verify
                                </button>
                              </div>
                              <div className="flex m-auto items-center mt-8 text-white">
                                Didn't receive a code?{" "}
                                <button
                                  onClick={() =>
                                    resendCode(
                                      props.values.email,
                                      props.values.password,
                                      props.values.mfaMethod
                                    )
                                  }
                                  className="text-cyan-300 hover:underline hover:text-cyan-800 ml-2"
                                >
                                  Resend Code
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="ml-10 mr-10 mt-10">
                      <div className="ml-5">
                        <Link
                          to="/signup"
                          className="font-extralight inline ml-2 text-white  hover:text-cyan-500"
                        >
                          New to Datasker? Sign up for free.
                        </Link>
                      </div>
                      <div className="m-5">
                        <Link
                          to="/resetPassword"
                          className="font-extralight inline ml-2 text-white  hover:text-cyan-500"
                        >
                          Forgot password? Reset now.
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <SignInFooter />
    </RouteTransition>
  );
};

export default Login;

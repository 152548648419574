type PromoContentProps = {
  children: React.ReactNode;
};
const PromoContent: React.FunctionComponent<PromoContentProps> = ({
  children,
}: PromoContentProps) => {
  return <>{children}</>;
};

export default PromoContent;

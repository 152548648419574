import ENV from "@config/env";

export const getTransactionUrl = (transactionId: string) => {
  if (!transactionId) return;

  const transactionUrl = ENV.HASHGRAPH_TRANSACTION_URL + "/transactions";

  const [payerAccountId, transaction] = transactionId.split("@");

  return `${transactionUrl}/${payerAccountId}-${transaction
    .split(".")
    .join("-")}`;
};

export const getLedgerTopicUrl = (topicId: string) => {
  if (!topicId) return;

  const topicUrl = ENV.HASHGRAPH_TRANSACTION_URL + "/topics";

  return `${topicUrl}/${topicId}`;
};

const GradientFeature: React.FunctionComponent = () => {
  return (
    <div className="bg-gradient-to-r from-purple-800 to-indigo-700">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white tracking-tight">
          Features to support your ESG-related disclosure
        </h2>
        <p className="mt-4 max-w-3xl text-lg text-purple-200">
          We provide a flexible and customisable blockchain solution to be
          integrated into your existing data flows.
        </p>
        <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
          <div>
            <div className="text-center">
              <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10 text-white">
                <i className="fad fa-search fa-lg" />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-lg font-medium text-white">Provide Audits</h3>
              <p className="mt-2 text-base text-purple-200">
                Data can be stored for provision to regulators, auditors, or
                commercial partners, with your complete control.
              </p>
            </div>
          </div>
          <div>
            <div>
              <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10 text-white">
                <i className="fad fa-check-double fa-lg" />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-lg font-medium text-white">
                Validate Input Data
              </h3>
              <p className="mt-2 text-base text-purple-200">
                Store data in flexible structures, or with rigidly defined
                schemas that validate for strict data integrity.
              </p>
            </div>
          </div>
          <div>
            <div>
              <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10 text-white">
                <i className="fad fa-link fa-lg" />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-lg font-medium text-white">
                Blockchain Selection
              </h3>
              <p className="mt-2 text-base text-purple-200">
                Choose between different blockchain platforms to suit your needs
                and budget.
              </p>
            </div>
          </div>
          <div>
            <div>
              <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10 text-white">
                <i className="fad fa-users fa-lg" />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-lg font-medium text-white">
                User Management
              </h3>
              <p className="mt-2 text-base text-purple-200">
                Add, edit and remove users so they have their own accounts to
                administer the organisation's ledgers.
              </p>
            </div>
          </div>
          <div>
            <div>
              <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10 text-white">
                <i className="fad fa-cogs fa-lg" />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-lg font-medium text-white">
                API Integration
              </h3>
              <p className="mt-2 text-base text-purple-200">
                Integrate easily within your existing workflow, sending simple
                requests of the data you need.
              </p>
            </div>
          </div>

          <div>
            <div>
              <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10 text-white">
                <i className="fad fa-hand-holding-usd fa-lg" />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-lg font-medium text-white">
                Low Cost Blockchain
              </h3>
              <p className="mt-2 text-base text-purple-200">
                Get the benefits of blockchain smart contracts without the high
                cost of custom development.
              </p>
            </div>
          </div>

          <div>
            <div>
              <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10 text-white">
                <i className="fad fa-flask fa-lg" />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-lg font-medium text-white">Aggregate Data</h3>
              <p className="mt-2 text-base text-purple-200">
                Aggregate data from multiple platforms and devices to create a
                single view of ESG-related disclosure records - one source of
                truth.
              </p>
            </div>
          </div>

          <div>
            <div>
              <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10 text-white">
                <i className="fad fa-chart-area fa-lg" />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-lg font-medium text-white">
                Digital Platform
              </h3>
              <p className="mt-2 text-base text-purple-200">
                Built with next generation open and secure technology removing
                vendor lock in.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GradientFeature;

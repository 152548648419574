import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Nav from "./layout/Nav";
import Dashboard from "./Dashboard";
import Ledgers from "./Ledgers";
import LedgersNew from "./LedgerNew";
import Integration from "./Integration";
import Settings from "./Settings";
import Ledger from "./Ledger";
import Entry from "./Entry";
import Entries from "./Entries";
import EntryNew from "./EntryNew";
import DefinitionNew from "./DefinitionNew";
import Definition from "./Definition";
import Definitions from "./Definitions";
import Documentation from "./documentation/Documentation";
import DocumentationLedgers from "./documentation/DocumentationLedgers";
import DocumentationEntries from "./documentation/DocumentationEntries";
import DocumentationDefinitions from "./documentation/DocumentationDefinitions";
import DocumentationRules from "./documentation/DocumentationRules";
import DocumentationIntegration from "./documentation/DocumentationIntegration";
import Footer from "./layout/Footer";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Notification from "./notification/Notification";
import SystemNotifications from "./notification/SystemNotifications";
import NotFound from "@error/NotFound";
import NetworkError from "@error/NetworkError";
import UnknownError from "@error/UnknownError";
import UserInfoChangeError from "@error/UserInfoChangeError";
import InternalServerError from "@error/InternalServerError";
import RuntimeError from "@error/RuntimeError";

const queryClient = new QueryClient();

const UserHome = () => {
  const location = useLocation();
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App mx-auto min-h-screen flex flex-col bg-gray-50">
        <Nav />
        <div className="max-w-7xl mx-auto px-4 sm:px-8 flex-grow flex flex-col w-full -mt-48">
          <AnimatePresence initial={false} mode="wait">
            <Routes location={location} key={location.key}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/documentation/" element={<Documentation />} />
              <Route
                path="/documentation/ledgers"
                element={<DocumentationLedgers />}
              />
              <Route
                path="/documentation/entries"
                element={<DocumentationEntries />}
              />
              <Route
                path="/documentation/definitions"
                element={<DocumentationDefinitions />}
              />
              <Route
                path="/documentation/rules"
                element={<DocumentationRules />}
              />
              <Route
                path="/documentation/integration"
                element={<DocumentationIntegration />}
              />
              <Route path="/ledgers/new" element={<LedgersNew />} />
              <Route path="/ledgers/:id/entries/new" element={<EntryNew />} />
              <Route path="/ledgers/:id/entries/" element={<Entries />} />
              <Route
                path="/ledgers/:id/definitions/new"
                element={<DefinitionNew />}
              />
              <Route
                path="/ledgers/:id/definitions/:definitionId"
                element={<Definition />}
              />
              <Route
                path="/ledgers/:id/definitions"
                element={<Definitions />}
              />
              <Route
                path="/ledgers/:id/integration"
                element={<Integration />}
              />
              <Route path="/ledgers/:id/settings" element={<Settings />} />
              <Route path="/ledgers/:id" element={<Ledger />} />
              <Route path="/ledgers" element={<Ledgers />} />
              <Route path="/entries/:id" element={<Entry />} />
              <Route
                path="/notifications/system"
                element={<SystemNotifications />}
              />
              <Route path="/notifications/inbox" element={<Notification />} />
              <Route path="/NetworkError" element={<NetworkError />} />
              <Route path="/UnknownError" element={<UnknownError />} />
              <Route
                path="/UserInfoChangeError"
                element={<UserInfoChangeError />}
              />
              <Route
                path="/InternalServerError"
                element={<InternalServerError />}
              />
              <Route path="/RuntimeError" element={<RuntimeError />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </AnimatePresence>
          <Footer />
        </div>
      </div>
    </QueryClientProvider>
  );
};

export default UserHome;

import { Link } from "react-router-dom";

import HeroImage from "../../images/hero.jpg";

import { ReactComponent as Logo } from "../../images/logo-image.svg";
import { ReactComponent as LogoText } from "../../images/logo-text.svg";

const Hero: React.FunctionComponent = () => {
  return (
    <div className="relative mb-1">
      <div className="absolute inset-x-0 bottom-0 h-1/2"></div>
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
          <div className="absolute inset-0">
            <img
              className="h-full w-full object-cover text-white"
              src={HeroImage}
              alt="Leaf"
            />
            <div className="absolute inset-0 bg-gradient-to-r from-purple-400 to-indigo-400 mix-blend-multiply"></div>
          </div>
          <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <div className="px-5 md:px-10 xl:px-20 grid grid-cols-5 md:space-x-8 space-y-2 md:space-y-0 place-items-center">
              <Logo className="w-24 h-24 md:w-full md:h-auto col-span-5 md:col-span-1" />
              <LogoText className="w-full h-auto md:border-l-2 md:col-span-4 col-span-5 md:pl-6" />
            </div>
            <p className="mt-10 xl:mt-16 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
              Datasker is an independent disclosure reporting tool built with
              blockchain technology to empower individuals, businesses and
              investors to self-assert their ESG-related credentials in real
              time.
            </p>
            <p className="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
              Disclosure drives action and markets.
            </p>
            <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
              <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-1 sm:gap-5">
                <Link
                  to="/signup"
                  className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

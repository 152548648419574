import { Link } from "react-router-dom";

// import ActivityFeed from "./ActivityFeed";
import LedgerList from "./ledger/LedgerList";
import RouteTransition from "./layout/RouteTransition";
import PageHeader from "./layout/PageHeader";

import { ReactComponent as IconPlus } from "../images/icon-plus.svg";
import { useQuery } from "@tanstack/react-query";
import { ledgersQuery } from "@utils/queries";

const Ledgers = () => {
  const { data: ledgers = [] } = useQuery(["ledgers"], ledgersQuery);

  return (
    <RouteTransition>
      <PageHeader pageTitle="Your Ledger" />
      <main className="flex flex-col lg:flex-row gap-6">
        <section className="bg-white rounded-lg w-full shadow-sm">
          <div className="flex w-full justify-between px-4 md:px-6 h-16 md:h-20 items-center border-b border-gray-200">
            <h3 className="text-xl md:text-2xl font-semibold">
              {ledgers.length} {ledgers.length > 1 ? "Ledgers" : "Ledger"}
            </h3>

            <Link to="/ledgers/new" className="flex-shrink-0">
              <button
                type="submit"
                className="flex items-center py-2 px-3 border border-transparent text-sm font-semibold rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <IconPlus className="w-4 mr-1" />
                Create Ledger
              </button>
            </Link>
          </div>
          <LedgerList ledgers={ledgers} />
        </section>
        {/* <ActivityFeed /> */}
      </main>
    </RouteTransition>
  );
};

export default Ledgers;

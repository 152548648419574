const Stats: React.FunctionComponent = () => {
  return (
    <div className="relative bg-gray-900">
      <div className="h-80 absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
        <div className="h-full w-full xl:grid xl:grid-cols-2">
          <div className="h-full xl:relative xl:col-start-2">
            <img
              className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
              src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=2830&amp;q=80&amp;sat=-100"
              alt="People working on laptops"
            />
            <div
              aria-hidden="true"
              className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
            ></div>
          </div>
        </div>
      </div>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
        <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
          <h2 className="text-sm font-semibold tracking-wide uppercase">
            <span className="bg-gradient-to-r from-purple-300 to-indigo-300 bg-clip-text text-transparent">
              Who is Datasker For?
            </span>
          </h2>
          <p className="mt-5 text-lg text-gray-300">
            Understanding and knowing an ESG footprint, setting actions to
            address - reducing, measuring, and performing to that action, being
            transparent about it – showing not just aspiration, but what the
            organisation has done.
          </p>
          <p className="mt-8 text-lg text-gray-300">Why? To be Authentic.</p>
          <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
            <p>
              <span className="mt-1 block text-base text-gray-300">
                <span className="font-medium text-white">
                  Organisations (individuals, for profit, profit for purpose){" "}
                </span>
                can integrate ESG-related information reported in their
                mainstream financial reports into custom Ledgers for disclosing
                regulatory compliance.
              </span>
            </p>

            <p>
              <span className="mt-1 block text-base text-gray-300">
                <span className="font-medium text-white">Regulators </span>
                can benefit from accessing organisation Ledgers in real time for
                auditing ESG-related regulatory compliance disclosures.
              </span>
            </p>

            <p>
              <span className="mt-1 block text-base text-gray-300">
                <span className="font-medium text-white">Stock Exchanges </span>
                can access listed companies' Ledgers in real time to verify
                compliance to ESG-related risks and opportunities.
              </span>
            </p>

            <p>
              <span className="mt-1 block text-base text-gray-300">
                <span className="font-medium text-white">Investors </span>
                can make informed and robust capital allocation decisions based
                on clarity, confidence and trust in ESG-related information
                disclosed in the organisation’s Ledgers.
              </span>
            </p>

            <p>
              <span className="mt-1 block text-base text-gray-300">
                <span className="font-medium text-white">Analysts </span>
                can be better equipped to utilise ESG-related information in
                determining impacts on future cash flow and ultimately company
                valuations.
              </span>
            </p>

            <p>
              <span className="mt-1 block text-base text-gray-300">
                <span className="font-medium text-white">
                  Accounting Firms{" "}
                </span>
                can be given comprehensive assurance of organisations and listed
                companies including investors are accurately reporting on
                ESG-related performances - no “green-washing”.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
